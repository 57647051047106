import { text as t } from 'shared/text';
import { cashValues } from './data';
import { getSubtotalSum } from 'components/Tables/tableUtils';

export const baseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.toLowerCase().includes('dev-portal.primerogames.com')) {
        return 'https://dev-api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('qa-portal.primerogames.com')) {
        return 'https://qa-api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('stage-portal.primerogames.com')) {
        return 'https://stage-api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('portal.primerogames.com')) {
        return 'https://api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('localhost')) { /// for connection to dev env from local
        return 'https://dev-api.trueroute.com';
    }

    return 'http://localhost';
};

export const formatGameNames = (val) => {
    switch (val) {
        case 'ALCHEMY':
            return 'Alchemy';
        case 'ALCHEMY_GRC_ONLINE':
            return 'Alchemy GRC Online';
        case 'ALCHEMY_GRC_ROOMS':
            return 'Alchemy GRC Rooms';
        case 'ARTO':
            return 'Arto';
        case 'ARTO_GRC_ONLINE':
            return 'Arto GRC Online';
        case 'ARTO_GRC_ROOMS':
            return 'Arto GRC Rooms';
        case 'BARX':
            return 'Bar X';
        case 'BARXTRIPLEPLAY_GRC_ONLINE':
            return 'Bar X Triple Play GRC Online';
        case 'BARXTRIPLEPLAY_GRC_ROOMS':
            return 'Bar X Triple Play GRC Rooms';
        case 'BingoGRCKiosk':
            return 'Bingo GRC Kiosk';
        case 'BINGOGRCKIOSK':
            return 'Bingo GRC Kiosk';
        case 'CAPTAINCASHFALLMEGAWAYS_GRC_ONLINE':
            return 'Captain Cashfall Megaways GRC Online';
        case 'CAPTAINCASHFALLTOTD':
            return 'Captain Cashfall TOTD';
        case 'CAPTAINCASHFALLTOTD_GRC_ONLINE':
            return 'Captain Cashfall TOTD GRC Online';
        case 'CAPTAINCASHFALLTOTD_GRC_ROOMS':
            return 'Captain Cashfall TOTD GRC Rooms';
        case 'CCTOTD':
            return 'CC TOTD';
        case 'COMMUNITYJACKPOTPRIMARY':
            return 'Community Jackpot Primary';
        case 'DRAGONSGEMS':
            return 'Dragon Gems';
        case 'DRAGONGEMSDELUXE': 
            return 'Dragon Gems Deluxe';
        case 'DRAGONGEMSDELUXE_GRC_ONLINE':
            return 'Dragon Gems Deluxe GRC Online';
        case 'DRAGONGEMSDELUXE_GRC_ROOMS':
            return 'Dragon Gems Deluxe GRC Online';
        case 'FREESPINSMOB':
            return 'Free Spins Mob';
        case 'FREESPINSMOB_GRC_ONLINE':
            return 'Free Spins Mob GRC Online';
        case 'FREESPINSMOB_GRC_ROOMS':
            return 'Free Spins Mob GRC Rooms';
        case 'ILOVETHE90S':
            return 'I Love the 90s';
        case 'ILOVETHENINETIES_GRC_ONLINE':
            return 'I Love the 90s GRC Online';
        case 'ILOVETHENINETIES_GRC_ROOMS':
            return 'I Love the 90s GRC Rooms';
        case 'LUCKYMONEY':
            return 'Lucky Money';
        case 'LUCKYMONEY_GRC_ONLINE':
            return 'Lucky Money GRC Online';
        case 'LUCKYMONEY_GRC_ROOMS':
            return 'Lucky Money GRC Rooms';
        case 'MAGICMERLINMEGAWAYS_GRC_ONLINE':
            return 'Magic Merlin Megaways GRC Online';
        case 'MAGICMERLINSPELLBOUND':
            return 'Magic Merlin Spellbound'
        case 'MAGICMERLINSPELLBOUND_GRC_ONLINE':
            return 'Magic Merlin Spellbound GRC Online';
        case 'MAGICMERLINSPELLBOUND_GRC_ROOMS':
            return 'Magic Merlin Spellbound GRC Rooms';
        case 'MAGICTRICKS':
            return 'Magic Tricks';
        case 'MAGICTRICKS_GRC_ONLINE':
            return 'Magic Tricks GRC Online';
        case 'MAGICTRICKS_GRC_ROOMS':
            return 'Magic Tricks GRC Rooms';
        case 'MEGAWAYSMOB':
            return 'Megaways Mob';
        case 'MEGAWAYSMOB_GRC_ONLINE':
            return 'Megaways Mob GRC Online';
        case 'MEGAWAYSMOB_GRC_ROOMS':
            return 'Megaways Mob GRC Rooms';
        case 'MONSTERSOFROCK':
            return 'Monsters of Rock';
        case 'MONSTERSOFROCK_GRC_ONLINE':
            return 'Monsters of Rock GRC Online';
        case 'MONSTERSOFROCK_GRC_ROOMS':
            return 'Monsters of Rock GRC Rooms';
        case 'ONELINESCRATCHCARD_GRC_ROOMS':
            return 'Online Scratch Card GRC Rooms';
        case 'PAYDAY':
            return 'Payday';
        case 'PAYDAY_GRC_ONLINE':
            return 'Payday GRC Online';
        case 'PAYDAY_GRC_ROOMS':
            return 'Payday GRC Rooms';
        case 'PAYDAYMEGAWAYS_GRC_ONLINE':
            return 'Payday Megaways GRC Online';
        case 'PAYDAYMEGAWAYS_GRC_ROOMS':
            return 'Payday Megaways GRC Rooms';
        case 'REELFRUITY_GRC_ONLINE':
            return 'Reel Fruity GRC Online';
        case 'ROCKYMOUNTAINREELS':
            return 'Rocky Mountain Reels';
        case 'ROCKYMOUNTAINREELS_GRC_ONLINE':
            return 'Rocky Mountain Reels GRC Online';
        case 'ROCKYMOUNTAINREELS_GRC_ROOMS':
            return 'Rocky Mountain Reels GRC Rooms';
        case 'SAFARIWONDERS':
            return 'Safari Wonders';
        case 'SAFARIWONDERS_GRC_ONLINE':
            return 'Safari Wonders GRC Online';
        case 'SAFARIWONDERS_GRC_ROOMS':
            return 'Safari Wonders GRC Rooms';
        case 'SCARABRICHES':
            return 'Scarab Riches';
        case 'SCARABRICHES_GRC_ONLINE':
            return 'Scarab Riches GRC Online';
        case 'SCARABRICHES_GRC_ROOMS':
            return 'Scarab Riches GRC Rooms';
        case 'SPELLBOUND':
            return 'Spellbound';
        case 'TICKETTOFORTUNE':
            return 'Ticket To Fortune';
        case 'TICKETTOFORTUNE_GRC_ONLINE':
            return 'Ticket To Fortune GRC Online';
        case 'TICKETTOFORTUNE_GRC_ROOMS':
            return 'Ticket To Fortune GRC Rooms';
        case 'TOTD':
            return 'TOTD';
        case 'TREASURESDEEP':
            return 'Treasures Deep';
        default:
            return val;
    }
}

export const filterDevicesByValue = (data, value) => {
    const newList = [];
    data.forEach((item) => {
        if (item.terminalTypeId === value) {
            newList.push(item)
        }
    })
    return newList;
}

export const getActionType = (prop) => {
    switch (prop) {
        case 'reboot':
            return 1;
        case 'pauseService':
            return 2;
        case 'startService':
            return 3;
        case 'sendLogs':
            return 4;
        case 'executeScript':
            return 7;
        case 'openSupportTunnel':
            return 9;
        case 'resetPOSAdminPassword':
            return 10;
        default:
            return null
    }
}

export const getCashData = (data, country) => {
    const array = [];
    const denomList = getDenomList(country)
    const keys = Object.keys(denomList);
    keys.forEach((key) => {
        const row = {};
        const count = data?.[key];
        row.denomination = denomList[key].label;
        row.count = count
        row.value = count * denomList[key].val;
        array.push(row)
    })
    return array;
}

const getDenomList = (country) => {
    switch (country) {
        case 'UG':
            return cashValues.ugd;
        default:
            return cashValues.us
    }
}

export const getDeviceLabel = (type) => {
    switch (type) {
        case 1:
            return t.primePay
        case 2:
            return t.grc
        case 3:
            return t.piggyBank
        case 4:
            return t.pos
        case 5:
            return t.monitor
        case 6:
            return t.piggyBankUG
        case 7:
            return t.bingo
        case 8:
            return t.primepay2
        default:
            return t.unknown
    }
}

export const getFinanceTypes = (types) => {
    return {
        primepayOnly: types?.includes(1) && types.length === 1,
        grc: types?.includes(2),
        deluxe: types?.includes(3),
        pos: types?.includes(4),
        monitor: types?.includes(5),
        ug: types?.includes(6),
        bingo: types?.includes(7),
        primepay2: types?.includes(8),
    }
}

export const getFinanceData = (types, data, records) => {
    const list = getFinanceTypes(types);
    if (list.primepayOnly) {
        return {
            data: { cashOut: data.cashOut },
            formats: ['currency'],
            headers: { cashOut: t.cashOut }
        }
    }

    const cardData = {
        cashIn: data.cashIn,
        cashOut: data.cashOut,
        profit: data.profit,
        hold: data.holdPercentage
    }
    const formats = ['currency', 'currency', 'currency', 'percentage-int'];
    const headers = {
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        profit: t.profit,
        hold: t.hold
    }
    if (list.pos) {
        cardData.reversal = data.refund;
        cardData.promo = data.promo;
        formats.push('currency');
        formats.push('currency');
        headers.reversal = t.refunds;
        headers.promo = t.promos;
    }
    if (list.grc || list.deluxe || list.pos) {
        cardData.escrow = data.escrow;
        formats.push('currency');
        headers.escrow = t.playerCredits
    }
    if (list.deluxe) {
        cardData.spinRate = getSpinRate(records, data.cashIn);
        formats.push('align-right');
        headers.spinRate = t.spinRate;
    }
    return {
        data: cardData,
        formats: formats,
        headers: headers
    }
}

const getSpinRate = (records, cashIn) => {
    const sum = getSubtotalSum('totalCashIn', records);
    return (cashIn ? sum / cashIn : 0).toFixed(2) + 'x';
}

export const getFinancesCard = (data, order) => {
    const object = {};
    order.forEach((key) => {
        object[key] = data[key];
    })
    return object;
}

export const isProduction = () => {
    return window.location.hostname.toLowerCase() === 'pwp.trueroute.com'
}

export const pullTabsParamMap = (params) => {
    const keyMap = {
        CompanyId: 'companyId',
        Search: 'searchTerm',
        PageIndex: 'currentPage',
        PageSize: 'rowCount',
        OrderBy: 'orderBy',
        Desc: 'desc',
    };
    return Object.fromEntries(
        Object.entries(params).map(([key, value]) => [keyMap[key] || key, value])
    );
}