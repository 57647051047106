import * as excel from 'xlsx-js-style';
import moment from 'moment';
import { hasDecimals, toStandardDate } from 'components/shared/componentUtils';
import { tablesData as td } from 'components/Tables/tablesData';
import { getCashData } from '../utils';
import { text as t } from 'shared/text';

const borderStyle = { style: 'medium', color: { rgb: '000000' }};

export const exportToFile = (name, array, sizes) => {
    const workbook = createWorkbook(array, sizes);
    const dateString = moment().format('MM.DD.YY-h.mm.ssA');
    excel.writeFile(workbook, `${name} (${dateString}).xlsx`);
}

const createWorkbook = (arrays, sizes) => {
    const workbook = excel.utils.book_new();
    const sheet = excel.utils.aoa_to_sheet([]);
    const length = arrays.length;
    let nextRow = 0;
    arrays.forEach((dataSet, index) => {
        const type = dataSet.type;
        const array = dataSet.data;
        if (type !== 'financials') {
            nextRow = addTable(dataSet, sheet, nextRow, index, length);
        } else if (type === 'financials') {
            if (dataSet.title) {
                const titleCell = [[dataSet.title]];
                excel.utils.sheet_add_aoa(sheet, titleCell, { origin: {r: nextRow, c: 0} });
                formatTitleCells(nextRow, sheet, 2);
                nextRow++
            }
            const formats = dataSet.formats;
            const data = array.map(obj => Object.values(obj));            
            excel.utils.sheet_add_aoa(sheet, data, { origin: {r: nextRow, c: 0} });
            formatExcelCells(nextRow, formats, sheet, data);
            nextRow += data.length + (index < arrays.length - 1 ? 1 : 0);
        }
        nextRow++;
    });
    setColumnWidths(sheet, sizes);
    excel.utils.book_append_sheet(workbook, sheet, 'CombinedTables');
    return workbook;
};

const addTable = (dataSet, sheet, nextRow, index) =>{
    const columns = dataSet.columns;
    const headers = dataSet.headers
    const configs = dataSet.configs;
    
    // TITLE
    const titleCell = [[dataSet.title]];
    excel.utils.sheet_add_aoa(sheet, titleCell, { origin: {r: nextRow, c: 0} });
    formatTitleCells(nextRow, sheet, columns.length);
    nextRow++

    // HEADERS
    const headerLabels = typeof headers === 'boolean' 
        ? [columns.map(column => t[column])]
        : [columns.map(key => headers[key])];
    excel.utils.sheet_add_aoa(sheet, headerLabels, { origin: {r: nextRow, c: 0} });
    formatTableHeaderCells(nextRow, sheet, columns, configs);
    nextRow++;

    // ROWS 
    let removeCount = 0;
    const array = dataSet.data;
    if (array.length === 0 || (configs.subtotals && array.length === 1)) {
        const startCol = 0;
        const endCol = dataSet.columns.length;
    
        // Merge the row across all columns
        sheet["!merges"] = sheet["!merges"] || [];
        sheet["!merges"].push({ s: { r: nextRow, c: startCol }, e: { r: nextRow, c: endCol - 1 } });
    
        excel.utils.sheet_add_aoa(sheet, [[t.noResults]], { origin: { r: nextRow, c: 0 } });

        const baseCellStyles = {
            font: { italic: true },
            alignment: { horizontal: "center", vertical: "center" }
        };

        for (let i = startCol; i < endCol; i++) {
            const cellRef = excel.utils.encode_cell({ r: nextRow, c: i });
            if (!sheet[cellRef]) {
                sheet[cellRef] = { t: 's', v: '' };
            }

            const cellStyles = {...baseCellStyles};
            cellStyles.border = {
                bottom: borderStyle,
            };
            if (i === 0) {
                sheet[cellRef] = { v: t.noResults };
                cellStyles.border.left = borderStyle;
            }
            if (i === endCol - 1) {
                cellStyles.border.right = borderStyle;
            }
            sheet[cellRef].s = cellStyles;
        }
        nextRow ++ 
    } else {
        const sectionStarts = []
        const data = array.map((row, i) => {
            const newRows = {};
            if (row.subtitle) {
                sectionStarts.push(i - removeCount);
                removeCount ++;
                return null; 
            } else {
                row = row.subtotals ? row.subtotals : row.totals ? row.totals : row;
                for (const key of columns) {
                    newRows[key] = row[key];
                }
                return Object.values(newRows);
            }
    
        }).filter(row => row !== null); 
        excel.utils.sheet_add_aoa(sheet, data, { origin: {r: nextRow, c: 0} });
        configs && formatTableCells(nextRow, configs, sheet, data, columns, sectionStarts);
        nextRow += data.length + (index < length - 1 ? 1 : 0);
        return nextRow;
    }
}

const formatTitleCells = (startRow, sheet, width) => {
    const baseCellStyles = {
        font: { bold: true, sz: 14 },
        alignment: { horizontal: 'center' },
        fill: {
            patternType: 'solid',
            fgColor: { rgb: 'ADD8E6' },
        },
    };

    for (let i = 0; i < width; i++) {
        const cellRef = excel.utils.encode_cell({ r: startRow, c: i });
        if (!sheet[cellRef]) {
            sheet[cellRef] = { t: 's', v: '' };
        }
        const cellStyles = { ...baseCellStyles };
        cellStyles.border = {
            top: borderStyle,
            bottom: borderStyle,
        };
        if (i === 0) {
            cellStyles.border.left = borderStyle;
        }
        if (i === width - 1) {
            cellStyles.border.right = borderStyle;
        }
        sheet[cellRef].s = cellStyles;
    }

    // Merge the range after applying the styles
    const mergeRange = {
        s: { r: startRow, c: 0 },
        e: { r: startRow, c: width - 1 }
    };

    // Ensure the merges property exists
    if (!sheet['!merges']) {
        sheet['!merges'] = [];
    }
    sheet['!merges'].push(mergeRange);
};

const formatExcelCells = (startRow, formats, sheet, data) => {
    const lastRow = startRow + data.length;
    for (let row=startRow; row<startRow + data.length; row++) {
        for (let column=0; column<2; column ++) {
            const cellStyles = {border: {}}
            const cellRef = excel.utils.encode_cell({ r: row, c: column });
            if (row === lastRow - 1 ) {
                cellStyles.border.bottom = borderStyle;
            }
            if (column === 0) {
                cellStyles.border.left = borderStyle;
                cellStyles.font = { bold: true }
                cellStyles.alignment = { horizontal: 'left' };
            } else {
                cellStyles.border.right = borderStyle
                cellStyles.alignment = { horizontal: 'right' };

                const format = formats[row - startRow];
                if (sheet[cellRef]) { formatCell(format, cellRef, {}, sheet) }
            } 
            sheet[cellRef].s = cellStyles;
        }
    }
}

const setColumnWidths = (sheet, sizes) => {
    const widths = []
    sizes.forEach((size) => {
        widths.push(getWidth(size));
    })
    sheet['!cols'] = widths;
}

const getWidth = (size) => {
    switch (size) {
        case 'xs':
            return {wch: 7}
        case 'small':
            return {wch: 12}
        case 'mid':
            return {wch: 17}
        case 'large':
            return {wch: 22}
        case 'xl':
            return {wch: 27}
        default:
            return {wch:17}
    }
}

const formatTableHeaderCells = (startRow, sheet, columns, configs) => {
    const layout = configs?.layouts ? configs.layouts : null;
    const length = columns.length;
    columns.forEach((column, colIndex) => {
        const cellRef = excel.utils.encode_cell({ r: startRow, c: colIndex });
        if (!sheet[cellRef]) return;

        const cellStyles = {
            border: {
                bottom: borderStyle,
                top: borderStyle,
            },
            fill: {
                patternType: 'solid',
                fgColor: { rgb: '90EE90' },
            },
            font: { bold: true }
        };
        if (layout && layout[column].includes('text-right')) {
            cellStyles.alignment = {horizontal: 'right'}
        }
        if (colIndex === 0) {
            if (!cellStyles.border) { cellStyles.border = {}}
            cellStyles.border.left = borderStyle;
        }
        if (colIndex === length - 1) {
            if (!cellStyles.border) { cellStyles.border = {}}
            cellStyles.border.right = borderStyle;
        }
        sheet[cellRef].s = cellStyles;
    })
}

const formatTableCells = (startRow, configs, sheet, data, columns, sectionStarts) => {
    const layouts = configs?.layouts;
    const formats = configs?.formats;
    const customNulls = configs?.customNulls;
    const length = columns.length;
    for (let row = startRow; row < startRow + data.length; row++) {
        const dataIndex = row - startRow;
        columns.forEach((column, i) => {
            const cellStyles = {};
            let format = formats?.[column];
            let skipCustomNull = false;
            if (sectionStarts.includes(dataIndex)) {
                cellStyles.border = {top: borderStyle};
            }
            const cellRef = excel.utils.encode_cell({ r: row, c: i });
            cellStyles.alignment = { horizontal: 'left', vertical: 'top' };
            // SUBTOTAL STYLING
            if (data[dataIndex][0] === td.results || data[dataIndex][0] === td.total) {
                skipCustomNull = true;
                format = configs.subtotals.formats[column];
                cellStyles.border = {bottom: borderStyle};
                cellStyles.font = { bold: true };
                if (format === 'one-in-x' && sheet[cellRef]) {
                    const content = sheet[cellRef].v; 
                    sheet[cellRef].v = !content.toFixed ? content :  '1 in ' + content.toFixed(2);
                    sheet[cellRef].t = 's';
                }
            } 
            if (layouts?.[column]?.includes('text-right')) {
                cellStyles.alignment = {horizontal: 'right'}
            }
            if (row === startRow + data.length - 1) {
                cellStyles.border = { bottom: borderStyle };
                if (configs?.subtotals) {cellStyles.border.top = borderStyle};
            }
            if (i === 0) {
                if (!cellStyles.border) { cellStyles.border = {}}
                cellStyles.border.left = borderStyle;
            }
            if (i === length - 1) {
                if (!cellStyles.border) { cellStyles.border = {}}
                cellStyles.border.right = borderStyle;
            }
            if (!sheet[cellRef]) { return; }
            if (customNulls && !skipCustomNull && !sheet[cellRef].v && customNulls[column]) {
                sheet[cellRef].v = customNulls[column];
                sheet[cellRef].t = 's'; // Ensure type is set to string after replacement
                format = null;
            } 
            format && formatCell(format, cellRef, cellStyles, sheet);
            sheet[cellRef].s = { ...sheet[cellRef].s, ...cellStyles };
        })
    }
}

const formatCell = (format, cellRef, cellStyles, sheet) => {
    if (format.includes('text-wrap')) {
        cellStyles.alignment.wrapText = true
    };
    if (format.includes('number') && hasDecimals(sheet[cellRef].v)) {
        sheet[cellRef].z = styles.decimal;
    }else if (format.includes('number') ) {
        sheet[cellRef].z = styles.number;
    } else if (format.includes('percentage-decimal-whole')) {
        sheet[cellRef].z = styles.percentageWhole;
    } else if (format.includes('percentage-decimal')) {
        sheet[cellRef].z = styles.percentage;
    } else if (format.includes('percentage-int')) {
        sheet[cellRef].v = sheet[cellRef].v / 10000;
        sheet[cellRef].z = styles.percentage;
    } else if (format.includes('percentage-whole')) {
        sheet[cellRef].v = sheet[cellRef].v / 100;
        sheet[cellRef].z = styles.percentageWhole;
    } else if (format.includes('percentage')) {
        sheet[cellRef].v = sheet[cellRef].v / 100;
        sheet[cellRef].z = styles.percentage;
    } else if (format.includes('currency-whole')) {
        sheet[cellRef].z = styles.currencyRounded;
    } else if(format.includes('currency')) {
        sheet[cellRef].z = styles.currency;
    } else if (format.includes('standard-date')) {
        sheet[cellRef].v = toStandardDate(sheet[cellRef].v);
    } else if (format.includes('text') && !sheet[cellRef].v) {
        sheet[cellRef].v = t.nullVal;
    }
}

export const getExcelDate = (date) => {
    return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
}

export const getExcelCash = (cashBreakdown, countryCode) => {
    return {
        title: countryCode ? `${t.cashBreakdown} (${countryCode})` : t.cashBreakdown,
        columns: ['denomination', 'count', 'value'],
        data: formatToCash(cashBreakdown, countryCode),
        configs: {
            layouts: {
                denomination: 'text-left',
                count: 'text-right',
                value: 'text-right'
            },
            formats: {value: 'currency'},
            subtotals: {
                fields: {
                    denomination: 'header',
                    gameTypePercentage: 'none',
                },
                formats: {value: 'currency'},
            }
        } 
    }
}

const formatToCash = (cashBreakdown, countryCode) => {
    const cash = getCashData(cashBreakdown, countryCode);
    cash.push({denomination: t.total, count : '', value: cash.reduce((sum, item) => sum + item.value, 0)});
    return cash;
}

export const getExcelFinancials = (financials) => {
    return {
        type: 'financials',
        title: t.financials,
        data: formatToFinancials(financials),
        formats: financials.formats,
    }
}

const formatToFinancials = (obj) => {
    const card = [];
    const data = obj.data;
    const headers = obj.headers;
    const keys = Object.keys(data);
    keys.forEach((key) => {
        card.push({
            property: headers[key],
            value: data[key] || 0
        })
    })
    return card;
}

export const getExcelTable = (title, columns, data, layout) => {
    return {
        title: title,
        columns: columns,
        headers: layout.tableHeaders,
        data: data,
        configs: layout,
    }
}

export const getExcelColumns = (layout) => {
    const keys = Object.keys(layout.tableHeaders);
    const layouts = layout.layouts;
    const headers = [];
    keys.forEach((key) => {
        if (layouts[key] !== 'hidden') {
            headers.push(key);
        }
    })
    return headers;
}

const styles = {
    currency: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',    
    currencyRounded: '"$"#,##0;[Red]\\("$"#,##0\\)',    
    decimal: '#,##0.00;[Red]-#,##0.00',
    number: '#,##0;[Red]-#,##0',
    percentage: '#,##0.00%;[Red]-#,##0.00%',
    percentageWhole: '0%;[Red]-0%',
};
