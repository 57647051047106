import './devicePullTabs.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { vendorsList } from 'shared/data';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
// import Checkbox from 'components/Checkbox/Checkbox';

const DevicePullTabs = () => {
    const { id } = useParams();
    const { getAuth } = useAuth();
    const { inputValues, updateNestedInputData } = useInputs();
    const { deviceTabs, setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { callAPI, callAPIGet, 
            updateDeviceTabs, updateNotice } = useUtils();
    const [ csvFile, setCsvFile ] = useState(null);
    const [ fileState, setFileState ] = useState('placeholder');
    const [ fileContent, setFileContent ] = useState(t.noFileSelected);
    // const [ availableFeatures, setAvailableFeatures ] = useState([]);
    // const features = ['featurePullTabs'];
    const runOnce = useRef(false);

    useEffect(() => {   
        if (runOnce.current) return;
        runOnce.current = true; 
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id});
        updateNestedInputData({selectedVendor: 'PTW'}, {selectedVendor: {list:0}}, [vendorsList]);
        // callAPI('featuresGet', featuresGetCallback);
    }, []);

    const deviceDetailsCallback = (data) => {
        const type = data.terminalType;
        setHeader(type.name + ': ' + data.terminalId);
        updateDeviceTabs(type.id);
        updateDeviceBreadcrumbs(data);
    }

    const onChooseClick = () => {
        document.getElementById('csv-input').click();
    }

    const changeFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.name.endsWith('.csv') || file.type === 'text/csv') {
                setCsvFile(file);
                setFileState('csv');
                setFileContent(file.name);
            } else {
                setFileState('error');
                setFileContent(t.errorInvalidCSV)
            }
        }
    };

    const onCSVClick = (setLoading) => {
        if (!csvFile) {return};
        setLoading(true);
        const formData = new FormData();
        formData.append('InputFile', csvFile); 
        formData.append('Vendor', inputValues.selectedVendor);
        formData.append('CompanyId', getAuth().companyId);
        const props = {
            type: 'blob',
            setLoading: setLoading,
            fileName: csvFile.name.slice(0, -4)
        }
        callAPI('pullTabsEncrypt', uploadCSVCallback, formData, props);
    }

    const uploadCSVCallback = (data, props) => {
        props.setLoading(false);
        updateNotice(data instanceof Blob ? {isSuccessful: true} : false);
        data && downloadFile(data, 'EncryptedFile_' + props.fileName + '_' + inputValues.selectedVendor + '.enc');
    }

    const downloadFile = (data, name) =>{
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = name;    
        link.click();    
        window.URL.revokeObjectURL(url);
    }

    // FEATURES CONTENT
    //    May be restored one day. Disabled for now.
    // const featuresGetCallback = (data) => {
    //     let array = data?.tableData ? data.tableData : [];
    //     setAvailableFeatures(array.length > 0 ? array.map(obj => obj.id) : array);
    // }

    // const onLicenseClick = (setLoading) => {
    //     const formData = new FormData();
    //     formData.append('TerminalId', parseInt(id));
    //     formData.append('FeatureIdList', calcFeatures()); 
    //     const props = {
    //         setLoading: setLoading,
    //         type: 'FormData'
    //     }
    //     callAPI('featureCreate', featureCreateCallback, formData, props);
    // }

    // const featureCreateCallback = (data, props) => {
    //     props.setLoading(false);
    //     updateNotice(data);
    //     data?.data && downloadFile(data.data, 'FeatureConfiguration_' + id + '.enc');
    // }

    // const calcFeatures = () => {
    //     let sum = 0;
    //     if (inputValues[features[0]]) {sum += 1}
    //     // TODO: add these in as needed - binary structure
    //     // if (inputValues[features[1]) {sum += 2};
    //     // if (inputValues[features[2]) {sum += 4};        
    //     // if (inputValues[features[3]) {sum += 8};
    //     return sum;
    // }

    return (
        <Page
            subPage={t.pullTabs}
            tabs={deviceTabs.tabs}
            contentClasses='device-pull-tabs grid'
        >  
            <div className='csv-container third'>
                <div className='pull-tabs-label'>{t.uploadCSV}:</div>
                <div className='row'>
                    <Button 
                        onClick={onChooseClick}
                    >{t.chooseCSV}</Button>
                    <input id='csv-input' type='file' accept='.csv' onChange={changeFile}/>
                    <div className= {`csv-file-display ${fileState}`}>{fileContent}</div> 
                </div>
            </div>
            <Dropdown
                id='selectedVendor'
                classes='sixth'
                label={t.selectVendor}
                data={vendorsList}
            />
            <Button 
                classes='pull-tabs-submit green sixth last'
                type='submit'
                disabled={fileState !== 'csv'}
                onClick={(event, setLoading) => {onCSVClick(setLoading)}}
            >{t.encryptAndDownload}</Button>
            {/* <div className='pull-tabs-label full'>{t.selectFeatures}:</div> */}
            {/* {availableFeatures.includes(1) && <div className='grid half'>
                <Checkbox 
                    id={features[0]}
                    local={true}
                    label={t.pullTabs} classes={'sixth'}
                />
            </div>}
            {availableFeatures.length === 0 && <div className='features-placeholder half'>{t.noFeaturesAvailable}</div>}
            <Button 
                classes='pull-tabs-submit green quarter last'
                type='submit'
                disabled={!inputValues[features[0]]}
                onClick={(event, setLoading) => {onLicenseClick(setLoading)}}
            >{availableFeatures.length > 1 ? t.generateLicensesAndDownload : t.generateLicenseAndDownload}</Button> */}
        </Page>
    )
}

export default DevicePullTabs;
