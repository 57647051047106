import { useEffect, useRef } from 'react';
import { addCellClasses, buildSpacer, 
         clearSelected, formatContent, 
         getDisplayedRows, getMax,
         showNullVal } from 'app-components/Table/tableUtils';
import { deepCopy, getNested, 
         isValid, sortList, toCurrency } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import Link from 'components/Link/Link';
import TableCell from '../TableCell/TableCell';

const TableBody = ({ layout, displayData, loading, country, cleanData, overflowEnabled, rowClickCallback }) => {
    const { tableStates, setTableStates } = useProfile();
    const latestState = useRef();
    const id = layout.id;
    const subtotals = layout.subtotals;
    const checkAll = tableStates?.[id]?.checkAll;
    const multiCheck = tableStates?.[id]?.multiCheck;

    useEffect (() => {
        latestState.current = tableStates?.[id];
    }, [tableStates])

    const buildRows = () => {
        if (!displayData || displayData.length <= 0 || !tableStates) {return (<div className='table-row empty'>{t.noResults}</div>)}
        const states = tableStates[id];
        if (!isValid(states)) {return};
        const properties = Object.keys(layout.tableHeaders);
        let rows;
        if (subtotals) {
            const groupId = subtotals.groupBy;
            rows = sortList(displayData, groupId);
            if (groupId !== 'all' && rows[0][groupId] !== rows[rows.length-1][groupId]) {rows = addSubtotalRows(rows, groupId)}
        } else {
            rows = getDisplayedRows(id, tableStates, displayData);
        }
        return rows.map((row, i) => {
            if (row.subtitle) {
                return <div key={i + '-subtitle'} className={`table-row subtitle`}>
                    <div className={`subtitle-cell table-cell ${subtotals.size} sticky-sticky-column`}>
                        <div className={'subtitle-cell-content'}>{row.subtitle}</div>
                    </div>
                    {buildSubtitleRow(row.subtitle)}
                </div>
            }
            else if (row.subTotalRow) {
                const vals = row.subTotalRow;
                return buildSubtotalsRow(i, vals.key, vals.val, rows);
            } else {
                const rowId = id + '-' + (row.id ? row.id : Object.values(row)[0]) + '-' + i;
                const selected = tableStates[id].selected;
                const noBottom = (i + 1 === rows.length && rows.length >= 10);
                return (
                    <div key={rowId.toString() + '-' + i} id={rowId.toString()} index={i} className={`table-row ${parseInt(selected) === rowId ? 'selected' : ''} ${noBottom ? 'no-bottom' : ''}`} onClick={(event)=>{onRowClick(event)}}>
                        {properties.map((cell, j) => {
                            return buildCells(row, cell, j, i);
                        })}
                    </div>
                )
            }
        })
    }

    const buildCells = (rowData, prop, columnIndex, rowIndex) => {
        const value = rowData[prop];
        if (Array.isArray(value)) {
            const maxCounts = getMax(prop, cleanData)
            const list = [];
            for (let k=0; k<maxCounts; k++) {
                const itemValue = value[k];
                const content = getCellContents(itemValue, prop, rowData, rowIndex);
                list.push(<TableCell key={k} index={rowIndex} layout={layout} prop={prop}>{content}</TableCell>)
            }
            return list;
        } else {
            const content = getCellContents(value, prop, rowData, rowIndex);
            const tooltip = layout.tooltips?.[prop];
            let tooltipInfo = null;
            if (tooltip && tooltip.prop) {
                const tooltipProp = tooltip.prop;
                tooltipInfo = {
                    prop: tooltipProp,
                    prefixes: tooltip.prefixes,
                    content: getTooltipContent(tooltipProp, tooltip, prop, rowData)
                }
            }

            return <TableCell 
                        key={columnIndex +'-' + rowIndex + '-cell'} 
                        index={rowIndex} 
                        layout={layout} 
                        prop={prop} 
                        tooltipInfo={tooltipInfo && tooltipInfo}
                    >{content}</TableCell>
        }
    }

    const getTooltipContent = (prop, data, cellProp, rowData) => {
        const tooltipPrefixes = data.prefixes;
        let baseContent = rowData[prop] ? rowData[prop] : data.nullVal;
        const cellContent = rowData[cellProp];
        if (tooltipPrefixes) {
            const rawVal = isValid(cellContent) ? cellContent.toString() : cellContent;
            return tooltipPrefixes[rawVal] + '\n' +  baseContent;
        } else {
            return baseContent;
        }
    }

    const getCellContents = (value, prop, rowData)=> {
        const id = rowData.id;
        if (isValid(layout?.navigation?.[prop])) {
            return buildLink(rowData, prop);
        }
        switch (prop) {
            case 'checkbox':
                const checked = ((multiCheck && multiCheck.includes(id)) || checkAll) ? true : false;
                return (
                    <label className='table-checkbox'>
                      <input
                        id={`table-multi-check-${id}`}
                        className='table-multi-check'
                        checked={checked}
                        data-id={id}
                        type='checkbox'
                        onChange={(event) => {onMultiCheckClick(event.target)}}
                      />
                      <span className='checkmark'></span>
                    </label>
                  );
            case 'cassettes':
            case 'stacker':
                return showNullVal(value) ? t.nullVal : value.currentCount + '/' + value.maxCount + ': ' + toCurrency(value.billValue ? value.billValue : 0, country);
            default:
                return formatContent(value, prop, layout);
        }
    }

    const buildLink = (rowData, prop) => {
        const configs = layout?.navigation?.[prop];

        const content = configs.onClick 
            ? (configs.labelStatic ?? formatContent(rowData[configs.label], prop, layout)) 
            : formatContent(rowData[configs.label], prop, layout);

        if (!configs) {return};
        if (content === t.nullVal && !configs.icon) {
            return content;
        } else {
            return <Link configs={configs} rowData={rowData}>
                {content}
            </Link>
        }
    }

    const onRowClick = (event) =>{
        const classList = event.target.classList;
        const newStates = deepCopy(tableStates);
        if (classList.contains('checkmark') ||
            classList.contains('table-multi-check') ||
            classList.contains('cell-tooltip') ) {return};
        const target = event.currentTarget;
        const classes = target.classList;
        if (classes.contains('selected')) {
            classes.remove('selected');
            newStates[id].selected = null;
        } else {
            clearSelected(id);
            const selectedId = latestState.current.selected;
            if (selectedId) {
                const classList = document.getElementById(selectedId)?.classList;
                classList && classList.remove('selected');
            }
            classes.add('selected');
            newStates[id].selected = target.id;
        }
        setTableStates(newStates);
        if (rowClickCallback) {
            rowClickCallback(event);
        }
    }

    const onMultiCheckClick = (target) => {
        const newStates = deepCopy(tableStates);
        const multiCheck = tableStates[id].multiCheck;
        const val = target.checked;
        const rowId = target.dataset.id;
        const array = multiCheck ?  [...multiCheck] : [];
        if (val) {
            array.push(rowId);
            newStates[id].multiCheck = array;
        } else {
            const newArray = array.filter((string) => string !== rowId);
            newStates[id].multiCheck = newArray;
        }
        newStates[id].checkAll = false;
        setTableStates(newStates);
    }

    const buildSubtitleRow = () => {
        if (!displayData) { return }
        const columns = Object.keys(layout.tableHeaders);
        const sizes = layout.sizes;
        return columns.map((column, j) => {
            return buildSpacer(sizes[column], null, j);
        })
    }

    const buildSubtotalsRow = (key, prop, val, rows) => {
        if (!displayData) { return }
        const columns = Object.keys(layout.tableHeaders);
        const sizes = layout.sizes;
        return <div key={key + '-subtotal'} className='table-row subtotal'>
            {columns.map((cell, j) => {
                const type = subtotals.fields[cell];
                if (j === 0) {
                    return <div key={key + '-' + j + '-subtotal'} className={`table-cell sticky-column ${sizes[cell]}`}>
                        <div className='table-cell-content'>{t.subtotals}</div>
                    </div>
                } else if (type) {
                    const content = formatContent(getSubtotal(type, cell, prop, val, rows), cell, layout);
                    return <div key={key + '-' + j + '-subtotal'} className={`table-cell ${addCellClasses(cell, layout, content ? content : 'filler', null)}`}>
                        <div className={`table-cell-content ${type ? 'subtotal ' + type : ''}`}>
                            {content}
                        </div>
                    </div>
                } else {
                    return buildSpacer(sizes[cell], null, j);
                }
            })}
        </div>
    }

    const addSubtotalRows = (rows, prop) => {
        const length = rows.length;
        let current = rows[0][prop];
        let newRows = [
            { subtitle: subtotals.label + ': ' + current}
        ]
        for (let i=0; i<length; i++) {
            const item = rows[i];
            const val = item[prop];
            if (item[prop] === current) {
                newRows.push(item);
            } else {
                newRows.push({ subTotalRow: {key: prop, val: current }});
                newRows.push({ subtitle: subtotals.label + ': ' + val})
                current = val;
                i--;
            }
        }
        newRows.push({ subTotalRow: {key: prop, val: rows[length-1][prop] }});
        return newRows;
    }

    const getSubtotal = (type, column,  prop, val, rows) => {
        const obj = layout.customCalculation[column];
        if (obj) {
            return getCustomSubtotal(obj, prop, val, rows);
        } else {
            switch (type) {
                case 'average':
                    return getSubtotalAverage(column,  prop, val, rows);
                case 'max':
                    return getSubtotalMax(column,  prop, val, rows)
                default:
                    return getSubtotalSum(column,  prop, val, rows);
            }
        }
    }

    const getCustomSubtotal = (obj, prop, val, rows) => {
        const first = obj.first;
        const second = obj.second;
        const firstVal = getSubtotal(first.type, first.column, prop, val, rows);
        const secondVal = getSubtotal(second.type, second.column, prop, val, rows);
        switch (obj.calc) {
            case 'divide':
                return (firstVal / secondVal) * 100;
            case 'multiply':
                return firstVal * secondVal;
            default:
                return firstVal / secondVal;
        }
    }

    const getSubtotalAverage = (column, prop, val, rows) => {
        const length = rows.length;
        let count = 0;
        let total = 0;
        for (let i=0; i<length; i++) {
            const item = rows[i];
            if (item[prop] === val) {
                count ++
                total += parseFloat(item[column]);
            }
        }
        return total/count;
    }

    const getSubtotalMax = (column, prop, val, rows) => {
        const length = rows.length;
        let max = 0;
        for (let i=0; i<length; i++) {
            const item = rows[i];
            if (item[prop] === val && item[column] > max) {
                max = item[column]
            }
        }
        return max;
    }

    const getSubtotalSum = (column, prop, val, rows) => {
        const length = rows.length;
        let total = 0;
        for (let i=0; i<length; i++) {
            const item = rows[i];
            if (item[prop] === val) {
                total += parseFloat(item[column]);
            }
        }
        return total;
    };

    return (
        <div id={`${id}-table-body`} className={`table-body ${layout.classes || ''}`} style={overflowEnabled}>
            <div className={`table-loading-mask ${!loading ? 'hide' : ''}`}>
                <span className='spinner'/>
            </div>
            {buildRows()}
        </div>
    )
}

export default TableBody;
