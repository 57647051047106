import './icon.scss';

import { AiOutlineAlert } from 'react-icons/ai';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';
import { ArrowDown } from 'react-bootstrap-icons';
import { ArrowDownUp } from 'react-bootstrap-icons';
import { ArrowUp } from 'react-bootstrap-icons';
import { BiSolidRightArrowSquare } from 'react-icons/bi';
import { Book } from 'react-bootstrap-icons';
import { BoxArrowInRight } from 'react-bootstrap-icons';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { BsCaretLeft } from "react-icons/bs";
import { CaretDown } from 'react-bootstrap-icons';
import { Cash } from 'react-bootstrap-icons';
import { Check } from 'react-bootstrap-icons';
import { CheckLg } from 'react-bootstrap-icons';
import { CiLocationOn } from 'react-icons/ci';
import { Clock } from 'react-bootstrap-icons';
import { Eye } from 'react-bootstrap-icons';
import { EyeSlash } from 'react-bootstrap-icons';
import { FaMinus } from 'react-icons/fa6';
import { FiEdit } from "react-icons/fi";
import { FiMinusCircle } from 'react-icons/fi';
import { FiPlusCircle } from 'react-icons/fi';
import { Gear } from 'react-bootstrap-icons';
import { GoChecklist } from 'react-icons/go';
import { GraphUp } from 'react-bootstrap-icons';
import { HddRack } from 'react-bootstrap-icons';
import { House } from 'react-bootstrap-icons';
import { ImStack } from 'react-icons/im';
import { LiaMoneyBillWaveSolid } from 'react-icons/lia';
import { LiaWrenchSolid } from 'react-icons/lia';
import { PatchExclamationFill } from 'react-bootstrap-icons';
import { People } from 'react-bootstrap-icons';
import { PinAngle } from 'react-bootstrap-icons';
import { PiBank } from 'react-icons/pi';
import { PiListBold } from "react-icons/pi";
import { Plus } from 'react-bootstrap-icons';
import { Printer } from 'react-bootstrap-icons';
import { QuestionCircle } from 'react-bootstrap-icons';
import { Receipt } from 'react-bootstrap-icons';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Search } from 'react-bootstrap-icons';
import { Telephone } from 'react-bootstrap-icons';
import { Trash3 } from 'react-bootstrap-icons';
import { XLg } from 'react-bootstrap-icons';

const Icon = ({icon, classes}) => {
    let image;
    switch (icon) {
        case 'alert': 
            image = <AiOutlineAlert/>;
            break;
        case 'arrow-counterclockwise':
            image = <ArrowCounterclockwise/>;
            break;
        case 'arrow-down':
            image = <ArrowDown/>;
            break;
        case 'arrow-down-up':
            image = <ArrowDownUp/>;
            break;
        case 'arrow-solid-right':
            image = <BiSolidRightArrowSquare/>;
            break;
        case 'arrow-up':
            image = <ArrowUp/>;
            break;
        case 'bank':
            image = <PiBank/>;
            break;
        case 'book':
            image = <Book/>;
            break;
        case 'cash':
            image = <Cash/>;
            break;
        case 'caret-down':
            image = <CaretDown/>;
            break;
        case 'check':
            image = <Check/>;
            break;
        case 'check-large':
            image = <CheckLg/>;
            break;
        case 'clock':
            image = <Clock/>;
            break;
        case 'delete':
            image = <RiDeleteBin6Line/>;
            break;
        case 'caret-left': 
            image = <BsCaretLeft/>
            break;
        case 'edit': 
            image = <FiEdit/>
            break;
        case 'eye':
            image = <Eye/>;
            break;
        case 'eye-slash':
            image = <EyeSlash/>;
            break;
        case 'gear':
            image = <Gear/>;
            break;
        case 'graph-up':
            image = <GraphUp/>;
            break;
        case 'house':
            image = <House/>;
            break;
        case 'list':
            image = <PiListBold/>;
            break;
        case 'list-check':
            image = <GoChecklist/>;
            break;
        case 'location':
            image = <CiLocationOn/>;
            break;
        case 'login': 
            image = <BoxArrowInRight/>;
            break;
        case 'logout':
            image = <BoxArrowRight/>;
            break;
        case 'minus':
            image = <FaMinus/>;
            break;
        case 'minus-circle': 
            image = <FiMinusCircle/>;
            break;
        case 'money':
            image = <LiaMoneyBillWaveSolid/>;
            break;
        case 'people':
            image = <People/>;
            break;
        case 'phone':
            image = <Telephone/>;
            break;
        case 'pin':
            image = <PinAngle/>;
            break;
        case 'plus':
            image = <Plus/>;
            break;
        case 'plus-circle': 
            image = <FiPlusCircle/>;
            break;
        case 'printer':
            image = <Printer/>;
            break;
        case 'question-circle':
            image = <QuestionCircle/>;
            break;
        case 'receipt':
            image = <Receipt/>;
            break;
        case 'search':
            image = <Search/>;
            break;
        case 'stack':
            image = <ImStack/>;
            break;
        case 'system':
            image = <HddRack/>;
            break;
        case 'trash':
            image = <Trash3/>;
            break;
        case 'wrench':
            image = <LiaWrenchSolid/>;
            break;
        case 'x-large':
            image = <XLg/>;
            break;
        default:
            image = <PatchExclamationFill/>;
            break;
    }
    return <i className={`icon ${icon} ${classes ? classes : ''}`}>{image}</i>
}

export default Icon;