import './locationSettings.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { deepCopy, isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const LocationSettings = () => {
    const { id }  = useParams();
    const { getCountry } = useAuth();
    const { inputValues, changeInputValues, 
            changeInputErrors, clearAllInputs,
            updateNestedInputData, reNestedData } = useInputs();
    const { locationTabs, updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { callAPI, callAPIGet,
            hasRank, updateNotice,
            updateLocationTabs } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ alerts, setAlerts ] = useState();
    const [ alertsContacts, setAlertsContacts ] = useState();
    const [ firstIndex, setFirstIndex ] = useState(0); 
    const [ secondIndex, setSecondIndex ] = useState(0);
    const [ masked, setMasked ] = useState(true);    const permitted = hasRank('owner', true);
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const deviceTypes = useRef();
    const country = useRef(getCountry());

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        callAPIGet('locationAlertsGet', locationAlertsGetCallback, {locationId: id})
        callAPIGet('locationAlertsContacts', locationAlertsContactsCallback, {locationId: id} )
        return () => {clearAllInputs()};
    }, []);

    useEffect(()=> {
        if (alerts && alertsContacts && !setOnce.current) {
            const newAlerts = deepCopy(alerts);
            const firstId = alerts.firstContact.id;
            const secondId = alerts.secondContact.id;
            const firstContact = alertsContacts.find(contact => contact.id === firstId)
            const secondContact = alertsContacts.find(contact => contact.id === secondId)
            if (firstContact) {
                newAlerts.firstContact.phone = firstContact.phone;
                newAlerts.firstContact.email = firstContact.email;
                newAlerts.firstContact.name = firstContact.name;
                newAlerts.firstContact.id = firstId;
            }
            if (secondContact) {
                newAlerts.secondContact.phone = secondContact.phone;
                newAlerts.secondContact.email = secondContact.email;
                newAlerts.secondContact.name = secondContact.name;
                newAlerts.secondContact.id = secondContact.id;
            }
            setOnce.current = true;
            const dropdownList = {
                ['firstContact-id']: {list: 0,  labelProp: 'label', appendProp: 'name'},
                ['secondContact-id']: {list: 0, labelProp: 'label', appendProp: 'name'}
            }
            const update = updateNestedInputData(newAlerts, dropdownList, [alertsContacts]);
            update && setMasked(false);
        }
    }, [alerts, alertsContacts])

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues])

    useEffect(() => {
        if (!isValid(firstIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const firstContact = alertsContacts[firstIndex]; 
        newValues['firstContact-phone'] = firstContact.phone;
        newValues['firstContact-email'] = firstContact.email;
        newValues['firstContact-name'] = firstContact.name;
        newValues['firstContact-contactId'] = firstIndex;
        newValues['firstContact-id'] = firstContact.id;
        if (!firstContact.id || !firstContact.phone) {
            newValues['firstContact-textAlert'] = false;
        }
        if (!firstContact.id || !firstContact.email) {
            newValues['firstContact-emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [firstIndex])

    useEffect(() => {
        if (!isValid(secondIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const secondContact = alertsContacts[secondIndex];
        newValues['secondContact-phone'] = secondContact.phone;
        newValues['secondContact-email'] = secondContact.email;
        newValues['secondContact-name'] = secondContact.name;
        newValues['secondContact-contactId'] = secondIndex;
        newValues['secondContact-id'] = secondContact.id;
        if (!secondContact.id || !secondContact.phone) {
            newValues['secondContact-textAlert'] = false;
        }
        if (!secondContact.id || !secondContact.email) {
            newValues['secondContact-emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [secondIndex])

    const locationDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.locationDetails;
            const types = data?.kioskTypes;
            deviceTypes.current = types;
            updateLocationTabs(types);
            setPageData(details);
            updateLocationHeader(details);
            updateLocationBreadcrumbs(details);
        } else {
            setPageData({})
        }
    }

    const locationAlertsGetCallback = (data) => {
        if (data.isSuccessful) {
            const {kioskAlerts, firstContact, secondContact} = data;
            setAlerts({kioskAlerts, firstContact, secondContact});
        }
    }

    const locationAlertsContactsCallback = (data) => {
        setAlertsContacts(data?.kioskAlertContacts ? data.kioskAlertContacts : []);
    }

    const onDropdownCallback = (data) => {
        if (data.section === 'firstContact') {
            setFirstIndex(data.index);
        } else {
            setSecondIndex(data.index);
        }
    }

    const onSubmit = (event, setLoading) => {
        const props = {event: event, setLoading: setLoading}
        setLoading(true);
        changeInputErrors({})
        const params = reNestedData(alerts);
        delete params.firstContact.phone;
        delete params.firstContact.email;
        delete params.secondContact.phone;
        delete params.secondContact.email;

        params.firstContact.contactId = parseInt(params.firstContact.contactId);
        params.secondContact.contactId = parseInt(params.secondContact.contactId);

        if (params.firstContact.id) {
            params.firstContact.name = alertsContacts.find(contact => contact.id === params.firstContact.id)?.name;
        }
        if (params.secondContact.id) {
            params.secondContact.name = alertsContacts.find(contact => contact.id === params.secondContact.id)?.name;
        }
        
        params.locationId = parseInt(id);
        callAPI('locationAlertsSet', locationServicesSetCallback, params, props);
    }

    const locationServicesSetCallback = (data, props) => {
        props.setLoading(false);
        updateNotice(data, props);
    }

    return (
        <Page
            subPage={t.settings}
            tabs={locationTabs.tabs}
            contentClasses='grid location-details'
        >
            {<Card label={t.grcAlerts} classes='full'>
                <Checkbox label={t.active} section='kioskAlerts' line='active' classes='third' noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTenUGD : t.alertForTen} section='kioskAlerts' line='lowRecycler10' classes='third'
                    type='max100' disabled={!inputValues?.['kioskAlerts-active']} masked={masked} noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTwentyUGD : t.alertForTwenty} section='kioskAlerts' line='lowRecycler20' classes='third'
                    type='max100' disabled={!inputValues?.['kioskAlerts-active']} masked={masked} noPermission={!permitted}/>

                <Dropdown label={t.primaryAlert} section='firstContact' line='id' classes='third' data={alertsContacts} callback={onDropdownCallback}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['kioskAlerts-active']} masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='firstContact' line='textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['firstContact-id'] || !inputValues?.['firstContact-phone']}/>
                <Input label={t.phoneNumber} section='firstContact' line='phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='firstContact' line='emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['firstContact-id'] || !inputValues?.['firstContact-email']}/>
                <Input label={t.email} section='firstContact' line='email' classes='quarter display-only' placeholder={t.none}
                    masked={masked} noPermission={!permitted}/>
                
                <Dropdown label={t.secondaryAlert} section='secondContact' line='id' classes='third' data={alertsContacts} callback={onDropdownCallback}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['kioskAlerts-active']} masked={masked}noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='secondContact' line='textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['secondContact-id'] || !inputValues?.['secondContact-phone']}/>
                <Input label={t.phoneNumber} section='secondContact' line='phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' disabled={!inputValues?.['kioskAlerts-active']} masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='secondContact' line='emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['secondContact-id'] || !inputValues?.['secondContact-email']}/>
                <Input label={t.email} section='secondContact' line='email' classes='quarter display-only' placeholder={t.none}
                    disabled={!inputValues?.['kioskAlerts-active']} masked={masked} noPermission={!permitted}/>

                {permitted && <Button
                    classes={`green last sixth`}
                    type='submit'
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                    >{t.submit}
                </Button>}
            </Card>}
        </Page>
    )
}

export default LocationSettings;
